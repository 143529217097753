import { useCallback, useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay';

import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';
import { getUserSessionRegions } from 'dibs-regional-info/exports/regionalInfoHelpers';

import { useSearchCorrections } from '../../useSearchCorrections';
import {
    trackEcommerceProductClick,
    trackEcommerceProductImpressions,
} from '../../../utils/tracking/searchBrowse/ecommerceTracking';
import { useSbSharedItemTracking_item$key } from './__generated__/useSbSharedItemTracking_item.graphql';
import { useSbSharedItemTracking_itemSearch$key } from './__generated__/useSbSharedItemTracking_itemSearch.graphql';
import { useSbSharedItemTracking_viewer$key } from './__generated__/useSbSharedItemTracking_viewer.graphql';

const viewerFragment = graphql`
    fragment useSbSharedItemTracking_viewer on Viewer {
        regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
            @include(if: $fetchRegionalInfo) {
            regionsByZipCode {
                displayName
            }
        }
    }
`;

const itemSearchFragment = graphql`
    fragment useSbSharedItemTracking_itemSearch on ItemSearchQueryConnection {
        ...ecommerceTracking_itemSearch
        ...useSearchCorrections_itemSearch
    }
`;

const itemFragment = graphql`
    fragment useSbSharedItemTracking_item on Item @relay(plural: true) {
        ...ecommerceTracking_item
    }
`;

type ItemData = {
    itemId: string | null;
    index: number;
    isSponsored?: boolean;
};

export type TrackingFunction = (props: ItemData) => void;

type Args = {
    viewer: useSbSharedItemTracking_viewer$key;
    itemSearch: useSbSharedItemTracking_itemSearch$key;
    items: useSbSharedItemTracking_item$key | null | undefined;
    isRelatedSearchItems?: boolean;
    pageType?: string;
};

const dedupeItem = ({ itemId, index }: ItemData): string => `${index}_${itemId}`;

export const useSbSharedItemTracking = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    items: itemsRef,
    isRelatedSearchItems,
    pageType,
}: Args): {
    fireItemClickTracking: TrackingFunction;
    fireItemImpressionTracking: TrackingFunction;
} => {
    const [trackedItemImpressionItems, setTrackedItemImpressionItems] = useState<string[]>([]);
    const [attemptedItemImpressionItems, setAttemptedItemImpressionItems] = useState<ItemData[]>(
        []
    );

    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const items = useFragment(itemFragment, itemsRef);
    const [searchCorrectionTerm] = useSearchCorrections({ itemSearch });

    const regionalInfoAvailable = useCallback(() => {
        return !!(getUserSessionRegions() || viewer?.regionalInfo);
    }, [viewer]);

    const getRegions = useCallback(() => {
        return getUserSessionRegions() || viewer?.regionalInfo?.[0]?.regionsByZipCode || [];
    }, [viewer]);

    const trackImpressions = useCallback(
        (itemsData: ItemData[]): void => {
            const userRegions = getRegions();

            trackEcommerceProductImpressions({
                items,
                itemSearch,
                itemsData,
                searchCorrectionTerm,
                userRegions,
                isRelatedSearchItems,
                pageType,
            });
            setTrackedItemImpressionItems(curr => [...curr, ...itemsData.map(dedupeItem)]);
            setAttemptedItemImpressionItems([]);
        },
        [
            getRegions,
            isRelatedSearchItems,
            items,
            itemSearch,
            pageType,
            searchCorrectionTerm,
            setAttemptedItemImpressionItems,
            setTrackedItemImpressionItems,
        ]
    );

    const [debouncedTracking] = useDebouncedCallback(trackImpressions, 200);

    useEffect(() => {
        if (attemptedItemImpressionItems.length > 0 && regionalInfoAvailable()) {
            debouncedTracking(attemptedItemImpressionItems);
        }
    }, [attemptedItemImpressionItems, debouncedTracking, regionalInfoAvailable]);

    const fireItemImpressionTracking: TrackingFunction = useCallback(
        item => {
            const dedupedItem = dedupeItem(item);
            //Checking if item impression is already fired or it is already in attemptedItemImpressionItems list
            if (
                !trackedItemImpressionItems.includes(dedupedItem) &&
                !attemptedItemImpressionItems
                    .map(attemptedItem => dedupeItem(attemptedItem))
                    .includes(dedupedItem)
            ) {
                setAttemptedItemImpressionItems(curr => [...curr, item]);
            }
        },
        [attemptedItemImpressionItems, trackedItemImpressionItems]
    );

    const fireItemClickTracking: TrackingFunction = useCallback(
        ({ itemId, index, isSponsored }) => {
            const userRegions = getRegions();
            //In case clicking on item while impression tracking haven't been done
            if (!trackedItemImpressionItems.includes(dedupeItem({ itemId, index }))) {
                trackImpressions([{ itemId, index, isSponsored }]);
                setAttemptedItemImpressionItems([]);
            }

            trackEcommerceProductClick({
                itemSearch,
                items,
                index,
                searchCorrectionTerm,
                isSponsored,
                pageType,
                userRegions,
            });
        },
        [
            itemSearch,
            items,
            getRegions,
            pageType,
            searchCorrectionTerm,
            trackedItemImpressionItems,
            trackImpressions,
        ]
    );

    return {
        fireItemClickTracking,
        fireItemImpressionTracking,
    };
};
