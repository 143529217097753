import { graphql, useFragment } from 'react-relay';

import { useSearchCorrections_itemSearch$key } from './__generated__/useSearchCorrections_itemSearch.graphql';
import { getFilterValue } from './SbSharedRefineMenu/sbSharedRefineMenuHelpers';

export function useSearchCorrections({
    itemSearch: itemSearchRef,
}: {
    itemSearch: useSearchCorrections_itemSearch$key;
}): [string | null] {
    const itemSearch = useFragment(
        graphql`
            fragment useSearchCorrections_itemSearch on ItemSearchQueryConnection {
                searchCorrections {
                    classificationSearchTerm
                    searchTerm
                    semanticSearchTerm
                }
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
        itemSearchRef
    );

    const { searchCorrections, appliedFilters } = itemSearch;
    const originalSearchTerm = getFilterValue(appliedFilters, 'originalSearch')?.urlLabel;

    const searchCorrectionTerm =
        searchCorrections?.semanticSearchTerm || // /search/?q=jewlry
        searchCorrections?.classificationSearchTerm || // /search/?q=bust%20sculpture
        searchCorrections?.searchTerm ||
        originalSearchTerm || // /jewelry/?oq=jewelry&st=semantic
        null;
    return [searchCorrectionTerm];
}
